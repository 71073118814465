import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import "../../graphql/fragments"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { HeadingBasic } from "../../components/heading/heading"
import { CoverMain } from "../../components/cover-main/cover-main"
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { Carousel } from "../../components/utils/render-functions"
import { OverseasProjectsSlider } from "../../components/glider/glider"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const OverseasBusiness = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerTownship.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "about-us/overseas-business") {
          title
          blocks {
            name
            ...CoreHeadingBlock
            ...CoreParagraphBlock
            ...CoreCoverBlock
            ...CoreGroupBlock
            ...AcfGalleryBlock
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreHeadingBlock
              ...CoreParagraphBlock
              ...CoreCoverBlock
              ...CoreGroupBlock
              ...AcfGalleryBlock
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        location(id: "international-projects", idType: SLUG) {
          projects {
            nodes {
              slug
              title
              excerpt
              projectTags {
                nodes {
                  name
                }
              }
              featuredImage {
                sourceUrl
              }
              propertyTypes {
                nodes {
                  name
                  slug
                }
              }
              translation(language: ID) {
                slug
                title
                excerpt
                projectTags {
                  nodes {
                    name
                  }
                }
                featuredImage {
                  sourceUrl
                }
                propertyTypes {
                  nodes {
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const projects = data.wordPress.location.projects.nodes
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const cover = wordPress && wordPress.find(x => x.name === "core/cover")
  let paragraphs = []

  if (
    wordPress &&
    wordPress.filter(x => x.name === "core/paragraph").length > 0
  ) {
    wordPress
      .filter(x => x.name === "core/paragraph")
      .map(item =>
        paragraphs.push(item.attributes.content.split("<br>").join(" "))
      )
  }

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />

      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {wordPress && (
        <>
          <CoverMain
            h1={
              data?.wordPress?.pageBy?.translation?.title ||
              data?.wordPress?.pageBy?.title
            }
            img={
              cover
                ? cover.attributes.url
                : data.imgCoverMain.childImageSharp.fluid
            }
            className="cover-md"
          >
            <Breadcrumbs
              path={location.pathname}
              className="breadcrumb-light"
            />
          </CoverMain>

          <section className="py-main">
            <div className="container container-md">
              <HeadingBasic text={paragraphs} className="mb-0" />
            </div>
          </section>

          <section className="sc-international-development pt-main">
            <div className="pb-main">
              {wordPress.map((item, i) => {
                if (item.name === "acf/acfgallery") {
                  return <Carousel data={item} />
                }
              })}
            </div>

            <div className="heading mb-5 mb-sm-down-4">
              <div className="container">
                <h2 className="h1">{"Pengembangan Internasional Kami"}</h2>
              </div>
            </div>
            {wordPress.map((item, i) => {
              if (item.name === "core/group") {
                return (
                  <OverseasProjectsSlider data={item} projects={projects} />
                )
              }
              return <div key={`empty-block-${i}`}></div>
            })}
          </section>
        </>
      )}
    </Layout>
  )
}

export default OverseasBusiness
